// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the cost
// ------------------------------------------------------------------------------

import { API, Logger } from 'aws-amplify'
import { getServiceProviderCounter, serviceRequestsBySPID } from '@/graphql/kdwqueries'
import { updateServiceProvider as updateServiceProviderMutation } from '@/graphql/mutations'
import { getUserSub } from '@/services/User'
import i18n from '@/plugins/i18n.js'
import { dateDiff, isStartingDateBigger, addMonths } from '@/helpers/GenericFunctions'

export const COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH = 5.99 // Cost that the SP have to pay per month for a monthly cost
export const COST_BASIC_USAGE_MONTHLY_FEE_PER_YEAR = 2.99 // Cost that the SP have to pay per month for a yearly cost
export const COST_BASIC_USAGE_LOGO_MONTHLY = 0.44 // cost in euro of the logo usage in the month. If you use it even 1 time, you pay this price for the month.
export const COST_BASIC_USAGE_RELATION_UNIT = 0.99 // cost in euro everytime we connect a SP to Enduser or the contrary.
export const COST_BASIC_USAGE_SERVICE_UNIT_ABOVE_THRESHOLD = 0.79 // The price of the service when the number of service used is going above the threshold defined by COST_BASIC_USAGE_SERVICE_THRESHOLD. Here 1 means 1 euro. Here we count the max. If you use it even 1 time, you pay this price for the month. Exemple, you are using 2 services during the month, this is free. You create an additinoal one and you activate it, then you have to pay for this fee for the month.
const COST_BASIC_USAGE_SERVICE_THRESHOLD = 1 // The number of services which are included in the Monthly pass
export const COST_BASIC_USAGE_MINIMUM = 5 // This is the minimum wallet cost that the SP can chose.

export const COST_BASIC_FREE_MONTH_PERIOD = 1 // The number of month the SP can use the service for free.

const logger = new Logger('CostLibrary')

// The service detail is composed of the module and the service itself.
// The nature of the service is composed of hit (number of time the service is used) or duration (duration of the service used)
// the picing for the duration is indicated in minutes
// basicCostStructure: '{ "serviceDetail":[{"title":"search.relation", "tag": "search.relation", "type": "hit/duration", "triggerVariable": "0", "triggerCap": "0", "priceVariable": "0", "priceCap": "0", "value": "10", "subprice": "10", "vouchername": "", "vouchercode": "", "vouchertype": "Discount/deduction", "voucherstartingdate": "", "voucherendingdate": "", "voucherdeductionamount": "", "subpricerecorded": "0", "onperiod": [{"start": "", "end": ""}]}  ]}',

  export async function fetchMonthCostSP () {
    logger.info('fetchMonthCostSP')
    const apiName = 'kdwevts'
    const path = '/sp/' + await getUserSub() + '/billingMonthly'
    var output = {}
    try {
      output = await API.get(apiName, path)
      logger.info('fetchMonthCostSP RestAPI output:' + JSON.stringify(output))
     } catch (err) {
      output.errorMessage = err.message
     }
    if (output.errorMessage) {
      logger.error('fetchMonthCostSP error:' + JSON.stringify(output))
      throw new Error(output.errorMessage)
    }
    return output
  }

  export async function fetchLogoUse () {
    logger.info('fetchLogoUse')
    const apiName = 'kdwevts'
    const dateNow = new Date()
    const mon = dateNow.getMonth() + 1
    const mm = (mon >= 10 ? mon : '0' + mon)
    const yy = dateNow.getFullYear().toString().substring(2, 4)
    const mmyy = mm + yy
    const path = '/sp/' + await getUserSub() + '/billingLogo/' + mmyy
    var output = {}
    try {
      output = await API.get(apiName, path)
      logger.info('fetchLogoUse RestAPI output:' + JSON.stringify(output))
     } catch (err) {
      output.errorMessage = err.message
      logger.error('err :', err)
     }
    if (output.errorMessage) {
      logger.error('fetchLogoUse error:' + JSON.stringify(output))
      throw new Error(output.errorMessage)
    }
    return output.isLogoUsed === true
  }

  // Retrieve the Monthly counter of the SP to prepare them for the front End
  export async function fetchSPCurrentMonthConsumption (listValue) {
    try {
      logger.info('fetchSPCountMonth')
      // We are starting by counting the nb of relation
      var apiData, listFromAPI
      var nbRelation = 0
      var myDate = new Date().toISOString().substr(0, 7)
      var totalCost = 0

      // ----- We compute the COST of the nb of relation
      nbRelation = await computeMonthlyRelation(await getUserSub(), myDate + '-01')
      var cost = nbRelation * COST_BASIC_USAGE_RELATION_UNIT
      totalCost = totalCost + cost
      listValue.splice(0, listValue.length)
      listValue.push({ text: 'costSP.countMonthRelation', value: nbRelation, cost: cost })

      // ----- We compute the COST of the logo
      var labelLogo
      const isLogoUsed = await fetchLogoUseByDate(await getUserSub(), myDate + '-01')
      if (isLogoUsed === true) {
        cost = COST_BASIC_USAGE_LOGO_MONTHLY
        labelLogo = i18n.t('costSP.yes')
      } else {
        cost = 0
        labelLogo = i18n.t('costSP.no')
      }
      totalCost = totalCost + cost
      listValue.push({ text: 'costSP.countMonthLogoUsed', value: labelLogo, cost: cost })

      // ----- We compute the COST of the service
      apiData = await API.graphql({ query: getServiceProviderCounter, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      if (apiData.data.getServiceProvider.countMonthMaxActiveService > COST_BASIC_USAGE_SERVICE_THRESHOLD) {
        cost = (apiData.data.getServiceProvider.countMonthMaxActiveService - COST_BASIC_USAGE_SERVICE_THRESHOLD) * COST_BASIC_USAGE_SERVICE_UNIT_ABOVE_THRESHOLD
      } else {
        cost = 0
      }
      totalCost = totalCost + cost
      listValue.push({ text: 'costSP.countMonthMaxActiveService', value: apiData.data.getServiceProvider.countMonthMaxActiveService, cost: cost })
      return totalCost.toFixed(2)
    } catch (err) {
      logger.error(err)
      alert(i18n.t('parameterLib.alertFailLoad'))
      return 0
    }
  }
  // Retrieve the Monthly counter of the SP to prepare them for the front End

  export async function fetchIsSPFreeTrial () {
    try {
      logger.info('fetchIsSPFreeTrial')
      const apiData = await API.graphql({ query: getServiceProviderCounter, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      var months = dateDiff(apiData.data.getServiceProvider.createdAt.substr(0, 10))

      // console.log(months)
      // console.log(COST_FREE_MONTH_PERIOD)
      // if (months < COST_FREE_MONTH_PERIOD) {
      //   return i18n.t('costSP.freeTrial_OnGoing')
      // }
      return { months: months, wallet: apiData.data.getServiceProvider.walletSize }
    } catch (err) {
      logger.error(err)
      alert(i18n.t('parameterLib.alertFailLoad'))
      return { months: 99, wallet: 0 }
    }
  }

  // Increase the amount of the monthly wallet
  export async function increaseWalletSize (walletValue) {
    try {
      logger.info('increaseWalletSize')
      const ServiceProviderToUpdate = { id: await getUserSub(), walletSize: walletValue + 1 }
      await API.graphql({ query: updateServiceProviderMutation, variables: { input: ServiceProviderToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      return true
    } catch (err) {
      logger.error(err)
      return false
    }
  }

// ---------------BILLING FUNCTIONS ----------------------------------------------------------------

function subComputeMonthlySubscriptionCost (date, createdAt, subscriptionType, subscriptionFreePeriod, subscriptionDate) {
  // Check if this is before the creation
  console.log('createdAt:', createdAt)
  console.log('Date to consider:', date)
  if (isStartingDateBigger(date, createdAt) === false) {
    // console.log('computeMonthlySubscriptionCost - before creation')
    return { subscriptionType: subscriptionType, value: 0, reason: 'beforecreation' }
  }
  // If we are in the trial period or before, the subcription cost is 0.
  if (isStartingDateBigger(date, addMonths(createdAt, parseInt(subscriptionFreePeriod))) === false) {
    // console.log('computeMonthlySubscriptionCost - date is during the trial period')
    return { subscriptionType: subscriptionType, value: 0, reason: 'freeperiod' }
  }
  // Now we check if this is a monthly or yearly cost
  if (subscriptionType === 'monthly') {
    // this is a monthly cost
    return { subscriptionType: subscriptionType, value: COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH, reason: 'normal' }
  }
  if (subscriptionType === 'yearly') {
    // this is a yearly cost
    const monthDiff = dateDiff(subscriptionDate, date)
    // console.log('----------', monthDiff)
    if ((monthDiff / 12) - Math.round(monthDiff / 12) === 0) {
      return { subscriptionType: subscriptionType, value: COST_BASIC_USAGE_MONTHLY_FEE_PER_YEAR * 12, reason: 'annualfee' }
    } else {
      return { subscriptionType: subscriptionType, value: 0, reason: 'annualfee' }
    }
  }
  return { subscriptionType: 'undefined', value: 0, reason: 'undefined' }
}

// Compute the monthly subcription cost for a specific month (Date) for a SP
// All the dates should be in the format YYYY-MM-DD
// Date is the date we want to compute the Monthly subcription cost
// parameters are the param of the SP
// createdAt is the date the SP is created
export function computeMonthlySubscriptionCost (parameters, date, createdAt) {
  // Now we check if this is a monthly or yearly cost
  if (parameters !== undefined) {
    const parametersLoaded = JSON.parse(parameters)
    return subComputeMonthlySubscriptionCost(date, createdAt, parametersLoaded.subscription.method, parametersLoaded.subscription.freeperiod, parametersLoaded.subscription.date.substring(0, 10))
    /*
    // Check if this is before the creation
    if (isStartingDateBigger(date, createdAt) === false) {
      console.log('computeMonthlySubscriptionCost - before creation')
      return { subscriptionType: parametersLoaded.subscription.method, value: 0, reason: 'beforecreation' }
    }
    // If we are in the trial period or before, the subcription cost is 0.
    if (isStartingDateBigger(date, addMonths(createdAt, parseInt(parametersLoaded.subscription.freeperiod))) === false) {
      console.log('computeMonthlySubscriptionCost - date is during the trial period')
      return { subscriptionType: parametersLoaded.subscription.method, value: 0, reason: 'freeperiod' }
    }
    if (parametersLoaded.subscription.method === 'monthly') {
      // this is a monthly cost
      return { subscriptionType: parametersLoaded.subscription.method, value: COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH, reason: 'normal' }
    }
    if (parametersLoaded.subscription.method === 'yearly') {
      // this is a yearly cost
      const monthDiff = dateDiff(parametersLoaded.subscription.date.substring(0, 10), date)
      // console.log('----------', monthDiff)
      if ((monthDiff / 12) - Math.round(monthDiff / 12) === 0) {
        return { subscriptionType: parametersLoaded.subscription.method, value: COST_BASIC_USAGE_MONTHLY_FEE_PER_YEAR * 12, reason: 'annualfee' }
      } else {
        return { subscriptionType: parametersLoaded.subscription.method, value: 0, reason: 'annualfee' }
      }
    }
    */
  }
}

/* COPY CREATED
export function computeMonthlySubscriptionCost (parameters, date, createdAt) {
  // Now we check if this is a monthly or yearly cost
  if (parameters !== undefined) {
    const parametersLoaded = JSON.parse(parameters)
    // Check if this is before the creation
    if (isStartingDateBigger(date, createdAt) === false) {
      console.log('computeMonthlySubscriptionCost - before creation')
      return { subscriptionType: parametersLoaded.subscription.method, value: 0, reason: 'beforecreation' }
    }
    // If we are in the trial period or before, the subcription cost is 0.
    if (isStartingDateBigger(date, addMonths(createdAt, parseInt(parametersLoaded.subscription.freeperiod))) === false) {
      console.log('computeMonthlySubscriptionCost - date is during the trial period')
      return { subscriptionType: parametersLoaded.subscription.method, value: 0, reason: 'freeperiod' }
    }
    if (parametersLoaded.subscription.method === 'monthly') {
      // this is a monthly cost
      return { subscriptionType: parametersLoaded.subscription.method, value: COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH, reason: 'normal' }
    }
    if (parametersLoaded.subscription.method === 'yearly') {
      // this is a yearly cost
      const monthDiff = dateDiff(parametersLoaded.subscription.date.substring(0, 10), date)
      // console.log('----------', monthDiff)
      if ((monthDiff / 12) - Math.round(monthDiff / 12) === 0) {
        return { subscriptionType: parametersLoaded.subscription.method, value: COST_BASIC_USAGE_MONTHLY_FEE_PER_YEAR * 12, reason: 'annualfee' }
      } else {
        return { subscriptionType: parametersLoaded.subscription.method, value: 0, reason: 'annualfee' }
      }
    }
  }
}
*/

// Compute the monthly relation cost for a specific month (Date) for a SP
// All the dates should be in the format YYYY-MM-DD
// Date is the date we want to compute the Monthly subcription cost
async function computeMonthlyRelation (serviceProviderID, date) {
    const dateShort = date.substring(0, 7)
    const apiData = await API.graphql({ query: serviceRequestsBySPID, variables: { serviceProviderID: serviceProviderID, createdAtShort: { eq: dateShort }, limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
    return apiData.data.ServiceRequestsBySPID.items.length
}

async function fetchLogoUseByDate (serviceProviderID, date) {
  // console.log(serviceProviderID)
  const apiName = 'kdwevts'
  const dateNow = new Date(date)
  // console.log(dateNow)
  const mon = dateNow.getMonth() + 1
  const mm = (mon >= 10 ? mon : '0' + mon)
  const yy = dateNow.getFullYear().toString().substring(2, 4)
  const mmyy = mm + yy
  const path = '/sp/' + serviceProviderID + '/billingLogo/' + mmyy
  // console.log(path)
  var output = {}
  try {
    output = await API.get(apiName, path)
    // console.log('fetchLogoUse RestAPI output:' + JSON.stringify(output))
    } catch (err) {
    output.errorMessage = err.message
    console.log('err :', err)
    }
  if (output.errorMessage) {
    logger.error('fetchLogoUse error:' + JSON.stringify(output))
    throw new Error(output.errorMessage)
  }
  return output.isLogoUsed === true
}
